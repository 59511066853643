<script>
import ENUMS from '@shared/messages/enums.json';

export default {
  messages: {
    statusClass: ENUMS.bundleStatusClass,
  },
  data() {
    return {
      isFetching: false,
    };
  },
  validations: {
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  methods: {
    getData() {
      this.isFetching = true;
      this.$store.dispatch('bundles/fetch')
        .finally(() => (this.isFetching = false));
    },
  },
  created() {
    this.getData();
  },
};
</script>

<template>
  <form @submit.prevent="!isFetching && getData()">
    <b-skeleton v-if="isFetching" height="400" />
    <template v-else>
      <ul v-if="$store.state.bundles.list.data.length > 0">
        <li
          v-for="bundle in $store.state.bundles.list.data"
          :key="bundle.uuid"
          class="box flex-between-center"
        >
          <div>
            <p>
              Pack : <b>{{ bundle.name }}</b>
              <br>
              <span class="tag mt-2">
                <b-icon
                  class="mr-1 is-size-9"
                  icon="circle"
                  :type="$options.messages.statusClass[bundle.status]"
                />
                <span v-t="`bundles.status.${bundle.status}`" />
              </span>
            </p>
          </div>
          <b-button class="ml-5" @click="$emit('choose', bundle)">
            Sélectionner
          </b-button>
        </li>
      </ul>
      <p v-else class="notification">
        Aucun pack.
      </p>
    </template>
  </form>
</template>
