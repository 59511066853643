<script>
import cloneDeep from 'lodash/cloneDeep';
import { randomAlphaNum } from '@shared/services/Utils';
import APIAffiliate from '@app/services/API/Affiliate';
import SimpleTrainingSearchList from '@app/components/SimpleTrainingSearchList.vue';
import SimpleCustomerSearchList from '@app/components/SimpleCustomerSearchList.vue';
import SimpleBundleSearchList from '@app/components/SimpleBundleSearchList.vue';

export default {
  components: { SimpleBundleSearchList, SimpleTrainingSearchList, SimpleCustomerSearchList },
  props: {
    affiliate: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      hasApplicationListShown: false,
      hasCustomerListShown: false,
      hasAdvancedSettingsOpen: false,
      chosenCustomer: null,
      chosenTrainings: [],
      trainingNames: {},
      bundleNames: {},
      trainingCommissionType: {},
      bundleCommissionType: {},
      storeCommissionType: 'percentage',
      newAffiliate: {
        customer_uuid: null,
        email: '',
        firstname: '',
        lastname: '',
        code: randomAlphaNum(10, false),
        applications: {
          store: true,
          store_percentage: null,
          store_amount: null,
          trainings: [],
          bundles: [],
        },
      },
    };
  },
  validations: {
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
    isFormContext() {
      return !(
        this.hasApplicationListShown
        || this.hasCustomerListShown
      );
    },
    storeTrainings() {
      return this.$store.state.trainings.list?.data || [];
    },
    storeBundles() {
      return this.$store.state.bundles.list?.data || [];
    },
    storeTrainingsByUUID() {
      return this.storeTrainings
        .reduce((prev, curr) => {
          prev[curr.uuid] = curr;
          return prev;
        }, {});
    },
    storeBundlesByUUID() {
      return this.storeBundles
        .reduce((prev, curr) => {
          prev[curr.uuid] = curr;
          return prev;
        }, {});
    },
    baseFields() {
      return {
        firstname: {
          type: 'text',
          label: 'Prénom',
          column: 'is-6',
          inputAttrs: {
            required: true,
            maxlength: 128,
            hasCounter: false,
          },
        },
        lastname: {
          type: 'text',
          label: 'Nom',
          column: 'is-6',
          inputAttrs: {
            required: true,
            maxlength: 128,
            hasCounter: false,
          },
        },
        email: {
          type: 'email',
          column: 'is-12',
          label: 'Adresse e-mail',
          inputAttrs: {
            required: true,
          },
        },
      };
    },
    affiliateFields() {
      return {
        code: {
          type: 'text',
          label: 'Clé d\'affiliation',
          column: 'is-12',
          tooltipHTML: `
            <p>
              Cette clé est personnalisée pour votre affilié,
              elle apparaitra dans ses liens d'affiliation.
            </p>
            <p class="mt-3">
              Cette clé n'est pas modifiable après l'ajout
              de l'affilié.
            </p>
          `,
          tooltipAttrs: {
            position: 'is-left',
            multilined: true,
          },
          inputAttrs: {
            required: true,
            maxlength: 20,
          },
        },
      };
    },
    commissionTypeField() {
      return {
        type: 'select',
        label: 'Type de commission',
        options: {
          percentage: 'Pourcentage',
          amount: 'Montant fixe',
        },
        inputAttrs: { required: true },
      };
    },
    storeFields() {
      return {
        'applications.store_percentage': {
          type: 'number',
          label: 'Pourcentage de la commission',
          column: 'is-12',
          inputAttrs: {
            min: 1,
            max: 100,
            step: 0.01,
          },
        },
        'applications.store_amount': {
          label: 'Montant de la commission',
          type: 'number',
          column: 'is-12',
          inputAttrs: {
            min: 1,
            step: 0.01,
          },
        },
      };
    },
    applicationsFields() {
      return {
        percentage: {
          type: 'number',
          label: 'Pourcentage de la commission',
          column: 'is-12',
          inputAttrs: {
            min: 1,
            max: 100,
            step: 0.01,
            required: true,
          },
        },
        amount: {
          label: 'Montant de la commission',
          type: 'number',
          column: 'is-12',
          inputAttrs: {
            min: 1,
            step: 0.01,
            required: true,
          },
        },
      };
    },
  },
  methods: {
    setAdvancedSettingsOpen() {
      this.hasAdvancedSettingsOpen = !!(
        this.affiliate
        || this.chosenCustomer
        || this.newAffiliate.applications.store === false
        || this.newAffiliate.applications.store_percentage
        || this.newAffiliate.applications.store_amount
        || this.newAffiliate.applications.trainings.length > 0
        || this.newAffiliate.applications.bundles.length > 0
      );
    },
    onCustomerSelected(customer) {
      this.chosenCustomer = customer;
      this.hasCustomerListShown = false;
    },
    onTrainingSelected(training) {
      this.hasApplicationListShown = false;

      if (['HIDDEN', 'DRAFT', 'CLOSED'].includes(training.status)) {
        this.$buefy.dialog.alert(`
          Vous ne pouvez pas sélectionner cette formation
          car elle n'est pas accessible publiquement.
        `);
        return;
      }

      if (this.trainingNames[training.uuid]) {
        return;
      }

      this.trainingNames[training.uuid] = training.name;
      this.$set(this.trainingCommissionType, training.uuid, 'percentage');
      this.newAffiliate.applications.trainings.unshift({
        uuid: training.uuid,
        percentage: null,
        amount: null,
      });
    },
    onBundleSelected(bundle) {
      this.hasApplicationListShown = false;

      if (bundle.status === 'DRAFT') {
        this.$buefy.dialog.alert(`
          Vous ne pouvez pas sélectionner cette formation
          car elle n'est pas accessible publiquement.
        `);
        return;
      }

      if (this.bundleNames[bundle.uuid]) {
        return;
      }

      this.bundleNames[bundle.uuid] = bundle.name;
      this.$set(this.bundleCommissionType, bundle.uuid, 'percentage');
      this.newAffiliate.applications.bundles.unshift({
        uuid: bundle.uuid,
        percentage: null,
        amount: null,
      });
    },
    removeTraining(uuid) {
      delete this.trainingNames[uuid];
      delete this.trainingCommissionType[uuid];
      this.newAffiliate.applications.trainings = this.newAffiliate
        .applications
        .trainings
        .filter((training) => (training.uuid !== uuid));
    },
    removeBundle(uuid) {
      delete this.bundleNames[uuid];
      delete this.bundleCommissionType[uuid];
      this.newAffiliate.applications.bundles = this.newAffiliate
        .applications
        .bundles
        .filter((bundle) => (bundle.uuid !== uuid));
    },
    onStoreCommissionTypeChange() {
      if (this.storeCommissionType === 'percentage') {
        this.newAffiliate.applications.store_amount = null;
      } else {
        this.newAffiliate.applications.store_percentage = null;
      }
    },
    onTrainingCommissionTypeChange(training, index) {
      if (this.trainingCommissionType[training.uuid] === 'percentage') {
        this.newAffiliate
          .applications
          .trainings[index]
          .amount = null;
      } else {
        this.newAffiliate
          .applications
          .trainings[index]
          .percentage = null;
      }
    },
    onBundleCommissionTypeChange(bundle, index) {
      if (this.bundleCommissionType[bundle.uuid] === 'percentage') {
        this.newAffiliate
          .applications
          .bundles[index]
          .amount = null;
      } else {
        this.newAffiliate
          .applications
          .bundles[index]
          .percentage = null;
      }
    },
    handle() {
      const newAffiliate = { ...this.newAffiliate };

      this.isLoading = true;
      let promise;

      if (this.affiliate) {
        delete newAffiliate.firstname;
        delete newAffiliate.lastname;
        delete newAffiliate.email;
        delete newAffiliate.customer_uuid;
        delete newAffiliate.code;

        promise = APIAffiliate.updateAffiliate(
          this.authStore.uuid,
          this.affiliate.uuid,
          newAffiliate,
        )
          .then(({ data }) => {
            this.$emit('update', data);
            this.$showMessage.success();
          });
      } else {
        if (this.chosenCustomer) {
          newAffiliate.customer_uuid = this.chosenCustomer.uuid;
          delete newAffiliate.firstname;
          delete newAffiliate.lastname;
          delete newAffiliate.email;
        } else {
          delete newAffiliate.customer_uuid;
        }

        promise = APIAffiliate.addAffiliate(
          this.authStore.uuid, newAffiliate,
        )
          .then(({ data }) => {
            this.$emit('create', data);
            this.$showMessage.success();
          });
      }

      promise.finally(() => (this.isLoading = false));
    },
    setExistingAffiliateData() {
      if (this.affiliate) {
        this.newAffiliate.applications = {
          ...this.newAffiliate.applications,
          ...cloneDeep(this.affiliate.applications),
        };

        if (this.newAffiliate.applications.store_amount) {
          this.storeCommissionType = 'amount';
        } else {
          this.storeCommissionType = 'percentage';
        }

        const trainingNames = {};

        this.newAffiliate.applications.trainings = this.newAffiliate
          .applications
          .trainings
          .filter((training) => {
            if (!this.storeTrainingsByUUID[training.uuid]) {
              return false;
            }

            trainingNames[training.uuid] = (
              this.storeTrainingsByUUID[training.uuid].name
            );

            if (training.amount) {
              this.$set(
                this.trainingCommissionType,
                training.uuid,
                'amount',
              );
            } else {
              this.$set(
                this.trainingCommissionType,
                training.uuid,
                'percentage',
              );
            }

            return true;
          });

        this.trainingNames = trainingNames;
        const bundleNames = {};

        this.newAffiliate.applications.bundles = this.newAffiliate
          .applications
          .bundles
          .filter((bundle) => {
            if (!this.storeBundlesByUUID[bundle.uuid]) {
              return false;
            }

            bundleNames[bundle.uuid] = (
              this.storeBundlesByUUID[bundle.uuid].name
            );

            if (bundle.amount) {
              this.$set(
                this.bundleCommissionType,
                bundle.uuid,
                'amount',
              );
            } else {
              this.$set(
                this.bundleCommissionType,
                bundle.uuid,
                'percentage',
              );
            }

            return true;
          });

        this.bundleNames = bundleNames;
      }
    },
  },
  mounted() {
    const loader = this.$buefy.loading.open({ container: this.$el });
    this.$store.dispatch('trainings/fetch')
      .then(() => {
        this.$store.dispatch('bundles/fetch')
          .then(() => {
            this.setExistingAffiliateData();
            this.setAdvancedSettingsOpen();
          });
      })
      .finally(() => loader.close());
  },
};
</script>

  <template>
    <AppCardModal
      class="is-relative"
      :tag="isFormContext ? 'form' : 'div'"
      @submit.prevent="isFormContext ? (dataIsValid(isLoading) && handle()) : ''"
    >
      <template v-if="affiliate" #title>
        Modifier un affilié
      </template>
      <template v-else #title>
        Ajouter un affilié
      </template>
      <template #body>
        <SimpleBundleSearchList
          v-if="hasApplicationListShown"
          @choose="onBundleSelected"
        />
        <SimpleTrainingSearchList
          v-if="hasApplicationListShown"
          @choose="onTrainingSelected"
          class="mt-6"
        />
        <SimpleCustomerSearchList
          v-else-if="hasCustomerListShown"
          @choose="onCustomerSelected"
        />
        <template v-else>
          <div v-if="affiliate" class="box is-info content">
            <p>
              Le prénom, nom et l'adresse e-mail de cet affilié
              sont modifiables en
              <router-link
                  :to="{
                  name: 'customer',
                  params: {uuid: affiliate.customer.uuid}
                }"
              >
                cliquant ici
              </router-link>.
            </p>
            <p class="has-text-weight-bold">
              Sa clé d'affiliation ({{ affiliate.code }}) n'est pas modifiable.
            </p>
          </div>
          <template v-else>
            <div
              v-if="chosenCustomer"
              class="box flex-between-center"
            >
              <div>
                <p class="has-text-weight-bold">
                  {{ chosenCustomer.firstname }}
                  {{ chosenCustomer.lastname }}
                </p>
                <p>
                  {{ chosenCustomer.email }}
                </p>
              </div>
              <b-button
                class="ml-5"
                type="is-danger"
                icon-left="trash"
                @click="chosenCustomer = null"
              />
            </div>
            <b-field v-else class="mb-5" label="Qui souhaitez-vous ajouter ?">
              <b-select v-model="hasCustomerListShown" expanded>
                <option :value="false">
                  Une nouvelle personne
                </option>
                <option :value="true">
                  Un apprenant existant
                </option>
              </b-select>
            </b-field>
            <div class="columns is-multiline">
              <template v-if="!chosenCustomer">
                <div
                  v-for="(field, k) in baseFields"
                  :key="k"
                  class="column mb-0"
                  :class="field.column"
                >
                  <GeneratorField
                    :fieldName="k"
                    :fieldDefinition="field"
                    :formData.sync="newAffiliate"
                    :formValidation="$v.newAffiliate"
                  />
                </div>
              </template>
              <div
                v-for="(field, k) in affiliateFields"
                :key="k"
                class="column mb-0"
                :class="field.column"
              >
                <GeneratorField
                  :fieldName="k"
                  :fieldDefinition="field"
                  :formData.sync="newAffiliate"
                  :formValidation="$v.newAffiliate"
                />
              </div>
            </div>
            <p class="notification">
              Par défaut, les commissions définies dans les paramètres de votre programme d'affiliation
              s'appliqueront pour cet affilié. Si vous souhaitez appliquer des commissions personnalisées,
              veuillez le faire dans la section ci-dessous.
            </p>
          </template>

          <AppToggleSection
            :isOpen="hasAdvancedSettingsOpen"
            :disabledScroll="true"
            :hasSep="false"
            @open="hasAdvancedSettingsOpen = true"
            @close="hasAdvancedSettingsOpen = false"
          >
            <template #title>
              Commissions personnalisées
            </template>
            <template #default>
              <div class="field">
                <b-radio
                  :native-value="true"
                  v-model="newAffiliate.applications.store"
                >
                  Pour tous les produits
                  <b-tooltip class="ml-2" multilined position="is-right">
                    <template #content>
                      Définissez, pour cet affilié, un pourcentage
                      ou un montant de commission personnalisé,
                      qui s'appliquera pour toutes vos formations et tous vos packs
                    </template>
                    <b-icon icon="info-circle" />
                  </b-tooltip>
                </b-radio>
              </div>
              <div class="field mb-6">
                <b-radio
                  :native-value="false"
                  v-model="newAffiliate.applications.store"
                >
                  Pour un ou plusieurs produits
                  <b-tooltip class="ml-2" multilined position="is-right">
                    <template #content>
                      Sélectionnez uniquement le ou les produits (formation ou bundle) pour
                      lesquelles vous souhaitez rétribuer votre affilié ;
                      L'affilié ne recevra aucune commission pour les produits non sélectionnées
                    </template>
                    <b-icon icon="info-circle" />
                  </b-tooltip>
                </b-radio>
              </div>
              <div
                v-if="newAffiliate.applications.store"
                key="storeFields"
                class="columns is-multiline"
              >

                <div class="column is-12 mb-0">
                  <BaseField
                    v-model="storeCommissionType"
                    :field="commissionTypeField"
                    @input="onStoreCommissionTypeChange"
                  />
                </div>
                <template v-for="(field, k) in storeFields">
                  <div
                    v-if="k === `applications.store_${storeCommissionType}`"
                    :key="k"
                    class="column mb-0"
                    :class="field.column"
                  >
                    <GeneratorField
                      :fieldName="k"
                      :fieldDefinition="field"
                      :formData.sync="newAffiliate"
                      :formValidation="$v.newAffiliate"
                    />
                  </div>
                </template>
              </div>
              <div
                v-else
                key="applicationsFields"
                class="field"
              >

                <p class="field">
                  <b-button
                    expanded
                    @click="hasApplicationListShown = true"
                  >
                    Ajouter un produit
                  </b-button>
                </p>

                <article
                  v-for="(bundle, i) in newAffiliate.applications.bundles"
                  :key="bundle.uuid"
                  class="box"
                >
                  <header class="flex-between-center mb-5">
                    <div class="is-inline-block">
                      Pack :
                      <span class="title is-5">
                        {{ bundleNames[bundle.uuid] }}
                      </span>
                    </div>
                    <b-button
                      type="is-text"
                      class="has-text-danger"
                      icon-left="trash"
                      @click="removeBundle(bundle.uuid)"
                    />
                  </header>
                  <div class="columns is-multiline">
                    <div class="column is-12 mb-0">
                      <BaseField
                        v-model="bundleCommissionType[bundle.uuid]"
                        :field="commissionTypeField"
                        @input="onBundleCommissionTypeChange(bundle, i)"
                      />
                    </div>
                    <template v-for="(field, k) in applicationsFields">
                      <div
                        v-if="k === bundleCommissionType[bundle.uuid]"
                        :key="k"
                        class="column mb-0"
                        :class="field.column"
                      >
                        <GeneratorField
                          :fieldName="`applications.bundles.${i}.${k}`"
                          :fieldDefinition="field"
                          :formData.sync="newAffiliate"
                          :formValidation="$v.newAffiliate"
                        />
                      </div>
                    </template>
                  </div>
                </article>
                <article
                  v-for="(training, i) in newAffiliate.applications.trainings"
                  :key="training.uuid"
                  class="box"
                >
                  <header class="flex-between-center mb-5">
                    <div class="is-inline-block">
                      Formation :
                      <span class="title is-5">
                        {{ trainingNames[training.uuid] }}
                      </span>
                    </div>
                    <b-button
                      type="is-text"
                      class="has-text-danger"
                      icon-left="trash"
                      @click="removeTraining(training.uuid)"
                    />
                  </header>
                  <div class="columns is-multiline">
                    <div class="column is-12 mb-0">
                      <BaseField
                        v-model="trainingCommissionType[training.uuid]"
                        :field="commissionTypeField"
                        @input="onTrainingCommissionTypeChange(training, i)"
                      />
                    </div>
                    <template v-for="(field, k) in applicationsFields">
                      <div
                        v-if="k === trainingCommissionType[training.uuid]"
                        :key="k"
                        class="column mb-0"
                        :class="field.column"
                      >
                        <GeneratorField
                          :fieldName="`applications.trainings.${i}.${k}`"
                          :fieldDefinition="field"
                          :formData.sync="newAffiliate"
                          :formValidation="$v.newAffiliate"
                        />
                      </div>
                    </template>
                  </div>
                </article>
              </div>
            </template>
          </AppToggleSection>
        </template>
      </template>
      <template #foot>
        <template v-if="hasApplicationListShown">
          <b-button
            type="is-text"
            @click="hasApplicationListShown = false"
          >
            Retour
          </b-button>
        </template>
        <template v-else-if="hasCustomerListShown">
          <b-button
            type="is-text"
            @click="hasCustomerListShown = false"
          >
            Retour
          </b-button>
        </template>
        <template v-else>
          <b-button type="is-text" @click="$emit('cancel')">
            Annuler
          </b-button>
          <b-button
            type="is-primary is-custom"
            native-type="submit"
            :loading="isLoading"
          >
            <template v-if="!!affiliate">
              Mettre à jour
            </template>
            <template v-else>
              Ajouter
            </template>
          </b-button>
        </template>
      </template>
    </AppCardModal>
  </template>
